
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    activeTab: {
      type: Number,
      required: true,
    },
    teacherId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateActiveTab(tab: number) {
      this.$router.push(
        `/teachers/teachersintroduction?id=${this.teacherId}&view=${tab}`
      );
      this.$emit("updateActiveTab", tab);
    },
  },
});
