
import { defineComponent, PropType } from "vue";

import axios from "axios";
import mixins from "../../mixins/index";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const config = {
  headers: {
    Authorization: loginToken,
  },
};

interface info {
  buy: boolean;
  classDate: string;
  cnName: string;
  coin: number;
  description: string;
  expirationDate: string;
  foreignName: string | null;
  id: string;
  category: string;
  language: string;
  launchDate: string;
  level: number;
  like: boolean;
  picture: string;
  sales: number;
  shoppingCart: boolean;
  title: string;
  type: number;
  coinDiscount: string | undefined;
  discountText: string | undefined;
}

export default defineComponent({
  name: "CourseCard",
  mixins: [mixins],
  data() {
    const productTypeMap = [null, "一對一視訊", "週課影片", "函授影片"];
    const favoriteStatus = false;

    return {
      productTypeMap,
      favoriteStatus,
      picture: "",
    };
  },
  props: {
    info: {
      type: Object as PropType<info>,
      required: true,
    },
  },
  methods: {
    openTab(link: string) {
      window.open(link);
    },
    calcDueDate(date: string) {
      const today = new Date().getTime();
      const diff = Math.abs(today - new Date(date).getTime());
      const days = Math.round(diff / (1000 * 3600 * 24));
      // 計算日期小於10天才顯示即將下架
      if (days <= 10) {
        return days + 1;
      } else {
        return null;
      }
    },
    addToFavorite(id: string, liked: boolean) {
      axios
        .patch(`${serverUrl}learn_shop/like/${id}`, { add: liked }, config)
        .then((res) => {
          if (res.status == 200) {
            // Show Alert
            const toast = document.getElementById(
              "custom-toast-item"
            )! as HTMLElement;
            toast.style.visibility = "visible";
            toast.style.opacity = "1";

            const toastText = document.getElementById(
              "custom-toast-item-text"
            )! as HTMLElement;
            toastText.innerText = liked ? "已加入收藏" : "已移除收藏";

            setTimeout(() => {
              toast.style.visibility = "hidden";
              toast.style.opacity = "0";
            }, 1000);

            this.favoriteStatus = liked;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addToCart(id: string) {
      axios
        .patch(`${serverUrl}learn_shop/cart/${id}`, { add: true }, config)
        .then((res) => {
          if (res.status == 200) {
            // Show Alert
            const toast = document.getElementById(
              "custom-toast-item"
            )! as HTMLElement;
            toast.style.visibility = "visible";
            toast.style.opacity = "1";

            const toastText = document.getElementById(
              "custom-toast-item-text"
            )! as HTMLElement;
            toastText.innerText = "已加入兌換清單";

            setTimeout(() => {
              toast.style.visibility = "hidden";
              toast.style.opacity = "0";
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.favoriteStatus = this.info.like;
    if (this.info.picture !== null) {
      this.picture = this.info.picture;
    } else if (this.info.category === "skill") {
      this.picture = "/static/img/onlineShop/productBannerSkill.png";
    } else if (this.info.type === 1) {
      this.picture = "/static/img/onlineShop/productBanner0.png";
    } else if (this.info.type === 2) {
      this.picture = "/static/img/onlineShop/productBanner1.png";
    } else if (this.info.type === 3) {
      this.picture = "/static/img/onlineShop/productBanner2.png";
    }
  },
});
