
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    isPendingTeacher: {
      type: Boolean,
      required: true,
    },
    isFollow: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateFollowTeacher(status: boolean) {
      this.$emit("updateFollowTeacher", status);
    },
  },
});
