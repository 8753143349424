
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}`;

import { defineComponent, PropType } from "vue";

import mixins from "../../mixins";

// Interface
import { teacherDetail } from "@/types/models";

import "video.js/src/css/vjs.scss";
import videojs from "video.js";
import Hls from "hls.js";
interface videoDataInterface {
  file: string;
  section: string;
  thumbnail: string;
}
export default defineComponent({
  mixins: [mixins],
  props: {
    isPendingTeacher: {
      type: Boolean,
      required: true,
    },
    teacherInfo: {
      type: Object as PropType<teacherDetail>,
      required: true,
    },
    introductoryVideo: {
      type: Object as () => videoDataInterface,
      required: true,
    },
  },
  data() {
    return {
      collapseBtnShow: false,
      thumbnail:
        "https://video.talc.com.tw/assets/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651/Thumbnails/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651.0000000.jpg",
      vidoe:
        "https://video.talc.com.tw/assets/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651/HLS/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651.m3u8",
      getVideo: false,
    };
  },
  methods: {
    hlsSupport() {
      let video = document.createElement("video");
      return Boolean(
        video.canPlayType("application/vnd.apple.mpegURL") ||
          video.canPlayType("audio/mpegurl")
      );
    },
    videoPlay() {
      // 影片播放 ↓
      let screenWidth = window.innerWidth;
      let video = null! as HTMLMediaElement;
      if (this.introductoryVideo.section === null) {
        return;
      }
      this.getVideo = true;
      let videoUrl = `${serverUrl}hls/${this.introductoryVideo.section}/HLS/${this.introductoryVideo.file}`;
      if (screenWidth >= 768) {
        video = document.getElementById("video")! as HTMLMediaElement;
      } else {
        video = document.getElementById("video-mobile")! as HTMLMediaElement;
      }
      const hls = new Hls();
      if (Hls.isSupported()) {
        hls.loadSource(
          // "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
          // "https://video.talc.com.tw/assets/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651/HLS/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651.m3u8"
          videoUrl
        );
        hls.attachMedia(video);
        // hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //   video.play();
        // });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src =
          // "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8";
          // "https://video.talc.com.tw/assets/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651/HLS/1652266105-ffbd28eb-d44a-4ddd-a8d5-849a8fd63651.m3u8";
          videoUrl;
        //   video.addEventListener("loadedmetadata", function () {
        //   video.play();
        // });
      }
      // var player = videojs('video', {width: 200, height: 113});
      // 影片播放 ↑
    },
  },
  watch: {
    introductoryVideo: function () {
      this.videoPlay();
    },
  },
  mounted() {
    let target: HTMLElement | null = null;
    // 透過時間循環的方式執行原因是在這個生命週期完成時還無法訪問到 DOM ，因此只好透過時間處理
    const interval = setInterval(() => {
      this.$nextTick(() => {
        target = document.getElementById("collapse") as HTMLElement;
        if (target) {
          // collapse 超過三行就會隱藏，大約是 66px 的高度，為了避免文本行數剛好是三行時判斷異常
          // 因此把判斷範圍在加大一點，這樣可以確保超過判斷條件一定是因為至少多了一行需要隱藏的文本
          if (target.offsetHeight > 70) {
            // 不可以在 HTML 結構內直接加入 class collapse ，否則無法真正判別到文本容器高度
            target.classList.add("collapse");
            // 設定最小高度用意是在收合時基準點偏移，才不會發生收合到最頂部的效果
            target.style.minHeight = "66px";
            // 確認文本高度需要收合功能時才會顯示按鈕
            this.collapseBtnShow = true;
          }
          clearInterval(interval);
        }
      });
    }, 10);
  },
});
