
import { defineComponent, ref } from "vue";
import TemplateTeacher from "@/views/templates/TemplateTeacher.vue";

import { Modal } from "bootstrap";
import axios from "axios";
import mixins from "../mixins";

// Interface
import { teacherFeedback, teacherDetail } from "@/types/models";

// Components
import Tab from "@/components/TeacherIntroduction/Tab.vue";
import AltTab from "@/components/TeacherIntroduction/AltTab.vue";
import RatingBlock from "@/components/TeacherIntroduction/RatingBlock.vue";
import NoticeBlock from "@/components/TeacherIntroduction/NoticeBlock.vue";
import ExperienceBlock from "@/components/TeacherIntroduction/ExperienceBlock.vue";
import FollowButton from "@/components/TeacherIntroduction/FollowButton.vue";
import Notification from "@/components/TeacherIntroduction/Notification.vue";
import OnlineShopProducts from "@/components/TeacherIntroduction/OnlineShopProducts.vue";
import WeekClassList from "@/components/TeacherIntroduction/WeekClassList.vue";
import HorizontalClassList from "@/components/TeacherIntroduction/HorizontalClassList.vue";
import ProductBlock from "@/components/TeacherIntroduction/ProductBlock.vue";
import Advertisement from "@/components/TeacherIntroduction/Advertisement.vue";
import TeacherProfile from "@/components/TeacherIntroduction/TeacherProfile.vue";
import CourseContent from "@/components/ThemeLesson/CourseContent.vue";
import Toast from "@/components/Toast/Toast.vue";
import RecommendedCoursesModal from "@/components/modal/CourseReserveContent.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const config = {
  headers: {
    Authorization: loginToken,
  },
};

interface weekClass {
  canReserve: boolean;
  classDate: number;
  classId: number;
  teacherId: number;
  cnName: string;
  description: string;
  foreignName: string;
  isReserved: false;
  keyVisual: string;
  photo: string;
  rating: number;
  subject: string;
  title: string;
  type: string;
}
interface remark {
  name: string;
  value: string | null;
}
export default defineComponent({
  components: {
    TemplateTeacher,
    Tab,
    AltTab,
    RatingBlock,
    NoticeBlock,
    ExperienceBlock,
    FollowButton,
    Notification,
    OnlineShopProducts,
    WeekClassList,
    HorizontalClassList,
    ProductBlock,
    Advertisement,
    TeacherProfile,
    CourseContent,
    Toast,
    RecommendedCoursesModal,
  },
  mixins: [mixins],
  data() {
    return {
      // 教師基本資料
      teacherInfo: {
        announcement: "",
        bigPhoto: null,
        classRoomId: "",
        classRoomPassword: "",
        cnName: "",
        followCount: -1,
        foreignName: null,
        id: -1,
        introduction: {
          resume: "",
          concept: "",
          shortIntroduction: null,
        },
        isFollow: false,
        onBoard: "",
        postCount: -1,
        rating: -1,
        ratingNumber: -1,
        smallPhoto: null,
        status: false,
      } as teacherDetail,

      // 教師介紹影片
      introductoryVideo: {
        file: "",
        section: "",
        thumbnail: "",
      },

      isPendingTeacher: true,
      isFollow: false,
      followers: 0,
      isPendingFollow: false,

      // 教師評價
      teacherRating: [] as Array<teacherFeedback>,
      isPendingRating: true,
      currentRatingPage: 1,
      totalRatingPage: 0,
      totalRatingNum: 0,

      // 教師課表
      isPendingClass: true, // 週課
      weekClass: [] as Array<weekClass>, // 週課
      displayWeekClass: 4, // 週課
      classTagFilter: "", // 週課
      isPendingWeekVideoClass: true, // 週課影片
      weekVideoClass: [] as Array<weekClass>, // 週課影片
      isPendingCorrClass: true, // 函授課程
      corrClass: [], // 函授課程

      // 通知選單
      dropdown: false,

      // 頁籤：簡介、貼文、課表(Mobile)
      activeTab: 1,

      // 自我介紹收合
      isExpanded: false,

      // 動態貼文顯示
      // toast顯示文本
      toastText: "",
      // 推薦課程彈出
      recommendedCoursesModal: null as any,
      recommendedCoursesModalInfo: {
        subjectImg: "" as string | null,
        teacherImg: "" as string | null,
        teacherId: 0 as number | null,
        time: "" as string | null,
        feature: "" as string | null,
        classType: "" as string | null,
        level: null as string | null,
        title: "" as string | null,
        content: "" as string | null,
        remark: [] as remark[],
        status: 0,
        link: "" as string | null,
        password: "" as string | null,
        teacherName: "" as string | null,
        type: "",
        subject: "",
        id: 0,
        timestamp: 0,
      },
      courseModalId: "TeachersIntroduction",
    };
  },
  methods: {
    // 取得學生評價
    fetchRatings(currentPage: number) {
      this.isPendingRating = true;

      axios
        .get(
          `${serverUrl}teainfo/${this.$route.query.id}/rating?page=${currentPage}&displayedNum=5`,
          config
        )
        .then((data) => {
          if (currentPage === 1) {
            this.teacherRating = data.data.data.data;
          } else {
            this.teacherRating = this.teacherRating.concat(data.data.data.data);
          }
          this.totalRatingPage = data.data.data.totalPage;
          this.currentRatingPage = data.data.data.currentPage;
          this.isPendingRating = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPendingRating = false;
        });
    },
    // 取得教師資料
    fetchTeacherInfo() {
      this.isPendingTeacher = true;

      axios
        .get(`${serverUrl}teainfo/${this.$route.query.id}`, config)
        .then((data) => {
          this.teacherInfo = data.data.data.teacherInfo;
          this.isFollow = data.data.data.teacherInfo.isFollow; // 追蹤教師狀態
          this.followers = data.data.data.teacherInfo.followCount; // 追蹤人數
          this.isPendingTeacher = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPendingTeacher = false;
        });
    },
    // 取得教師開課列表
    fetchTeacherClass() {
      this.isPendingClass = true;

      axios
        .get(
          `${serverUrl}class_search?showAll=1&type=B&limit=120&teacher=${this.$route.query.id}`,
          config
        )
        .then((data) => {
          this.weekClass = data.data.data.course;
          this.isPendingClass = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPendingClass = false;
        });
    },
    // 顯示更多教師開課列表
    showMoreWeekClass() {
      const classLeft = this.weekClass.length - this.displayWeekClass;
      this.displayWeekClass += classLeft < 4 ? classLeft : 4;
    },
    // 轉換日期
    getDayFromTimestamp(timestamp: number) {
      const date = new Date(timestamp * 1000);
      return this.convertWeek3(date.getDay());
    },
    // 追蹤教師 | 取消追蹤教師
    followTeacher(follow: boolean) {
      this.isPendingFollow = true;
      const url = `${serverUrl}teainfo/${this.$route.query.id}/follow`;
      const config = {
        headers: {
          Authorization: loginToken,
        },
      };

      if (follow) {
        axios
          .post(url, {}, config)
          .then((res) => {
            if (res.status == 201) {
              this.isFollow = true;
              this.followers = this.followers + 1;
              this.isPendingFollow = false;
            }
          })
          .catch((e) => {
            // console.log(e);
          });
      } else {
        axios
          .delete(url, config)
          .then((res) => {
            if (res.status == 200) {
              this.isFollow = false;
              this.followers = this.followers - 1;
              this.isPendingFollow = false;
            }
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    },
    // 關閉提示方塊
    hideToast() {
      const toast = document.getElementById(
        "custom-toast-item"
      )! as HTMLElement;
      toast.style.visibility = "hidden";
      toast.style.opacity = "0";
    },

    // 動態貼文顯示 ↓
    updateFollowingPost(obj: { info: string; status: boolean; id: number }) {
      this.showToast({ info: obj.info });
    },
    // obj.info就是要顯示的文字內容
    showToast(obj: { info: string }) {
      // console.log("showtoast")
      this.toastText = obj.info;
      (this as any).$refs.Toast.showToast();
    },
    // 複製貼文成功失敗顯示
    copyPostLink(obj: { info: string }) {
      this.showToast({ info: obj.info });
    },
    updatePostLikeStatus(obj: {
      postId: number;
      status: boolean;
      postIndex: number;
      messageIndex?: number;
      replyIndex?: number;
    }) {
      if (obj.status !== true) {
        this.showToast({ info: "你按讚了貼文" });
      } else {
        this.showToast({ info: "你收回按讚" });
      }
    },
    updatePostMessage(obj: { info: string }) {
      this.showToast({ info: obj.info });
    },
    deleteMessageReply(obj: { info: string }) {
      this.showToast({ info: obj.info });
    },
    editMessage(obj: { info: string }) {
      this.showToast({ info: obj.info });
    },
    editReply(obj: { info: string }) {
      this.showToast({ info: obj.info });
    },
    newCollectionError() {
      this.showToast({ info: "收藏分類名稱重複" });
    },
    noSelectCollectionCategoryId() {
      this.showToast({ info: "尚未選擇收藏分類" });
    },
    modalOpenRecommendedCourses(obj: { index: number }) {
      this.recommendedCoursesModalInfo = this.courseReserveDataConvert(
        this.weekClass[obj.index]
      );
      // this.recommendedCoursesModalInfo = this.weekClass[obj.index];
      this.recommendedCoursesModal.show();
    },
    modalCloseRecommendedCourses(obj: { index: number }) {
      // this.weekClass[obj.index].isReserved = true;
      this.recommendedCoursesModal.hide();
    },
    // 動態貼文顯示 ↑

    // 教師介紹影片取得
    introductoryVideoGet() {
      axios
        .get(`${serverUrl}teainfo/${this.$route.query.id}`, config)
        .then((data) => {
          this.introductoryVideo = data.data.data.teacherInfo.introductionVideo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateFollowTeacherStatus(obj: { teacherId: number; status: boolean }) {
      (this.$refs as any).courseContent.updateFollowTeacherStatus(obj);
    },
  },
  watch: {
    pageRoute() {
      this.activeTab = parseInt(`${this.$route.query.view}`);
    },
  },
  computed: {
    pageRoute() {
      return this.$route.query.view;
    },
  },
  mounted() {
    // 切換 View
    if (this.$route.query.view) {
      const entryPoint = parseInt(`${this.$route.query.view}`);
      this.activeTab = entryPoint;
    } else {
      this.activeTab = 1;
    }

    // 取得教師資料
    this.fetchTeacherInfo();

    // 取得教師介紹影片
    this.introductoryVideoGet();

    // 取得教師評價
    this.fetchRatings(this.currentRatingPage);

    // 取得教師開課列表
    this.fetchTeacherClass();

    // Modal 初始化，推薦課程彈出視窗
    this.recommendedCoursesModal = new Modal(
      document.getElementById(`modal-${this.courseModalId}`)! as HTMLElement
    );
  },
  beforeMount() {
    this.recommendedCoursesModal = null;
  },
});
