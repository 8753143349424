
import { defineComponent, PropType, ref } from "vue";

import { teacherDetail } from "@/types/models";

import mixins from "../../mixins";
import ProductBlock from "@/components/TeacherIntroduction/ProductBlock.vue";

interface weekClass {
  canReserve: boolean;
  startClassDate: number;
  endClassDate: number;
  classId: number;
  cnName: string;
  description: string;
  foreignName: string;
  isReserved: false;
  keyVisual: string;
  photo: string;
  rating: number;
  subject: string;
  title: string;
  type: string;
}

export default defineComponent({
  components: { ProductBlock },
  props: {
    activeTab: {
      type: Number,
      required: true,
    },
    isPendingTeacher: {
      type: Boolean,
      required: true,
    },
    teacherInfo: {
      type: Object as PropType<teacherDetail>,
      required: true,
    },
    isPendingClass: {
      type: Boolean,
      required: true,
    },
    classList: {
      type: Array as PropType<Array<weekClass>>,
      required: true,
    },
  },
  emits: ["openRecommendedCourses"],
  data() {
    const langColorMap = {
      en: "#25A2A6",
      jp: "#25A2A6",
      kr: "#25A2A6",
      es: "#25A2A6",
      de: "#25A2A6",
      fr: "#25A2A6",
      th: "#25A2A6",
      ielts: "#524FA6",
      toefl: "#524FA6",
      graphic_design: "#25A2A6",
      programming_language: "#25A2A6",
      workplace_tools: "#25A2A6",
    } as { [key: string]: string };

    return {
      langColorMap,
      classTagFilter: "", // 週課
    };
  },
  methods: {
    // 轉換日期
    getDayFromTimestamp(timestamp: number) {
      const date = new Date(timestamp * 1000);
      return this.convertWeek3(date.getDay());
    },
    // 語言別切換
    updateTagFilter(lang: string) {
      if (this.classTagFilter === lang) {
        this.classTagFilter = "";
      } else {
        this.classTagFilter = lang;
      }
    },
    openRecommendedCourses(obj: { index: number }) {
      this.$emit("openRecommendedCourses", obj);
    },
  },
  mixins: [mixins],
  mounted() {
    console.log(this.classList);
    this.classList.forEach((item) => {
      console.log(this.langColorMap[item.subject]);
    });
  },
});
