
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductBlock",
  props: {
    teacherName: {
      type: String,
      required: true,
    },
    blockTitle: {
      type: String,
      required: true,
    },
  },
});
