
import { defineComponent, PropType } from "vue";
import axios from "axios";
import mixins from "../../mixins";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface teacherRating {
  cnName: string;
  code: string;
  customer_no: string;
  id: number;
  language: string;
  message: string | null;
  photo: string;
  rate: number;
  timeStamp: number;
  type: string;
}

export default defineComponent({
  props: {
    isPending: {
      type: Boolean,
      required: true,
    },
    ratings: {
      type: Number,
      required: true,
    },
    ratingNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // 教師評價
      teacherRating: [] as Array<teacherRating>,
      isPendingRating: true,
      currentRatingPage: 1,
      totalRatingPage: 0,
      totalRatingNum: 0,
    };
  },
  mixins: [mixins],
  methods: {
    // 取得學生評價
    fetchRatings(currentPage: number) {
      axios
        .get(
          `${serverUrl}teainfo/${this.$route.query.id}/rating?page=${currentPage}&displayedNum=5`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((data) => {
          if (currentPage === 1) {
            this.isPendingRating = true;
            this.teacherRating = data.data.data.data;
            this.isPendingRating = false;
          } else {
            this.teacherRating = this.teacherRating.concat(data.data.data.data);
          }

          // this.teacherRating = []; // 暫時修改資料

          this.totalRatingPage = data.data.data.totalPage;
          this.currentRatingPage = data.data.data.currentPage;
        })
        .catch((err) => {
          console.log(err);
          this.isPendingRating = false;
        });
    },
  },
  mounted() {
    // 取得教師評價
    this.fetchRatings(this.currentRatingPage);
  },
});
