
import { defineComponent, PropType } from "vue";
import CourseCard from "@/components/OnlineShop/CourseCardNew.vue";

import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    CourseCard,
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    teacher: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
  },
  data() {
    const productTypeMap = [null, "一對一視訊", "週課影片", "函授影片"];

    return {
      productTypeMap,
      isPendingClass: true,
      totalProductCnt: 0,
      classList: [],
    };
  },
  mounted() {
    // 取得教師商品
    this.isPendingClass = true;

    let params = {
      page: 1,
      displayedNum: 8, // 超過可顯示範圍，判斷是否需顯示「查看更多」
      type: this.type,
      teacher: this.teacher,
    };

    axios
      .post(`${serverUrl}learn_shop/list`, params, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then((res) => {
        this.classList = res.data.data.data;
        this.totalProductCnt = res.data.data.total;
        this.isPendingClass = false;
      })
      .catch((err) => {
        this.isPendingClass = false;
      });
  },
});
