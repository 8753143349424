
import { defineComponent, PropType } from "vue";

interface introduction {
  resume: string | null;
  concept: string | null;
  shortIntroduction: string | null;
}

export default defineComponent({
  props: {
    isPending: {
      type: Boolean,
      required: true,
    },
    introduction: {
      type: Object as PropType<introduction>,
      required: true,
    },
  },
});
