
import { defineComponent } from "vue";

import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
const config = {
  headers: {
    Authorization: loginToken,
  },
};

export default defineComponent({
  props: {
    size: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 假資料：廣告
      advertisement: [] as Array<{
        size: string;
        data: Array<{ image_url: string; link: string }>;
      }>,
      isPendingAd: false,
    };
  },
  methods: {
    dismissAd(size: string) {
      const target = document.getElementById(
        `advertise-${size}`
      )! as HTMLElement;
      target.style.visibility = "hidden";
      target.style.opacity = "0";
      setTimeout(() => {
        target.style.display = "none";
      }, 200);
    },
    getAdvertisement(size: string) {
      const filtered = this.advertisement.filter((e) => e.size === size);
      if (filtered.length > 0 && filtered[0].data.length > 0) {
        return filtered[0].data[0].image_url;
      } else {
        return "https://talcapi-dev.ourscool.com.tw/api/download?section=advert&f=1679995857-1da86244-64ec-4719-b2ce-46530c658129.jpg";
      }
    },
    directTo(size: string) {
      const filtered = this.advertisement.filter((e) => e.size === size);
      if (filtered.length > 0) {
        window.open(filtered[0].data[0].link);
      }
    },
  },
  mounted() {
    this.isPendingAd = true;

    axios
      .get(`${serverUrl}advert/introduceTeacher`, config)
      .then((res) => {
        if (res.status === 200) {
          this.advertisement = res.data.data;
          this.isPendingAd = false;
        } else {
          console.log("Error");
          this.isPendingAd = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isPendingAd = false;
      });
  },
});
