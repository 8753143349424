
import { defineComponent } from "vue";
import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  props: {
    dropdown: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPending: true,
      notification: {} as { post: boolean; reply: boolean; week: boolean },
    };
  },
  methods: {
    updateDropdown(status: boolean) {
      this.$emit("updateDropdown", status);
    },
    updateSetting(type: string, value: boolean) {
      let setting: { post: boolean; reply: boolean; week: boolean } =
        this.notification;

      switch (type) {
        case "posts":
          setting.post = value;
          setting.reply = value;
          break;
        case "post":
          setting.post = value;
          break;
        case "week":
          setting.week = value;
          break;
        case "reply":
          setting.reply = value;
          break;
        default:
          break;
      }

      axios
        .patch(`${serverUrl}ann/notify/${this.$route.query.id}`, setting, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((data) => {
          //   this.isPending = false;
        })
        .catch((err) => {
          console.log(err);
          //   this.isPending = false;
        });
    },
  },
  mounted() {
    this.isPending = true;

    axios
      .get(`${serverUrl}ann/notify/${this.$route.query.id}`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((data) => {
        this.notification = data.data.data;
        this.isPending = false;
      })
      .catch((err) => {
        console.log(err);
        this.isPending = false;
      });
  },
});
